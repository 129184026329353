import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter13 from '../common/src/assets/image/charity/specter/kapitola_13_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Trinásta kapitola: Ovládnutie médií | Ako duch komunizmu vládne nášmu svetu"
           description="Trinásta kapitola: Ovládnutie médií"
           image={imageChapter13}/>
        <h1>Trinásta kapitola: Ovládnutie médií</h1>
        <p>Vplyv médií v modernej spoločnosti je obrovský a&nbsp;rastie každým dňom. Preniká do komunít všetkých veľkostí, od lokálnych až po globálne. S tým, ako rastie popularita sociálnych médií a&nbsp;šíri sa obsah vytvorený používateľmi, internet výrazne umocnil rýchlosť a dosah audiovizuálnej komunikácie.</p>
        <p>Ľudia sa spoliehajú na médiá pri zisťovaní nových správ a analýz. Médiá, od novín a časopisov cez rozhlas, film a&nbsp;televíziu až po internetové stránky a sociálne siete, ovplyvňujú v&nbsp;oceáne informácií to, čo ľudia uvidia a tiež to, ako si to vyložia. Médiá sú schopné ovplyvňovať prvé dojmy ľudí na určitú tému, a tak majú značnú moc pri vytváraní psychologického primingu <em>(kognitívny proces, pri ktorom podnet z minulosti ovplyvňuje neskoršie správanie a myslenie jedinca, pozn. prekl.).</em> Pre spoločenské elity, a zvlášť politikov, môžu médiá rozhodnúť o tom, na čo sa bude zameriavať verejná mienka, a&nbsp;pre verejnosť slúžia médiá ako oporný bod. Témy, ktoré médiá pokrývajú, sa stávajú predmetom vážneho spoločenského záujmu. Udalosti, o ktorých sa nepíše, bývajú ignorované a zabudnuté.</p>
        <p>Na Západe sú médiá tradične považované za strážcu pravdy a základných hodnôt spoločnosti. Novinári sú rešpektovaní pre svoje odborné znalosti a tiež pre obete, ktoré prinášajú. Ich úlohou je pravdivo informovať o hlavných udalostiach vo svete a robiť tak presne a včas. Musia podporovať spravodlivosť a odsúdiť protiprávnosť, a súčasne s tým presadzovať dobrotu. Ich poslanie presahuje súkromné ​​záujmy akéhokoľvek jednotlivca, spoločnosti alebo politickej strany. Thomas Jefferson, hlavný autor Deklarácie nezávislosti a tretí prezident Spojených štátov, raz zhrnul dôležitú povinnosť, ktorú tlač v spoločnosti preberá: „Keby som mal rozhodnúť, či by sme mali mať vládu bez novín, alebo noviny bez vlády, ani chvíľu by som neváhal a uprednostnil by som to druhé.“<a href="#_edn1" id="_ednref1">[1]</a></p>
        <p>Ako hlas spoločnosti môžu médiá slúžiť buď ako ochranca morálky, alebo ako nástroj zla. Avšak uprostred morálneho úpadku ľudstva je pre médiá ťažké uchrániť si poctivosť a vykonávať svoju povinnosť aj pod tlakom moci a&nbsp;zoči-voči lákadlám peňažného zisku.</p>
        <p>Novinár a nakladateľ Joseph Pulitzer, po ktorom je pomenovaná Pulitzerova cena, raz povedal: „Vzostup či pád našej republiky je spojený s tlačou. Schopná, nezaujatá, spoločensky angažovaná tlač, ktorá dokáže rozpoznať to, čo je správne, a má odvahu to urobiť, môže zachovať onú verejnú počestnosť, bez ktorej by bola vláda ľudu podvodom a výsmechom. Cynická, žoldnierska, demagogická tlač tiež časom vyprodukuje ľudí podľa svojej podlej ideológie. Sila formovať budúcnosť republiky bude v rukách novinárov budúcich generácií.“<a href="#_edn2" id="_ednref2">[2]</a></p>
        <p>V komunistických krajinách sú médiá riadené štátom. Tieto hlásne trúby režimu vymývajú masám mozgy a sú spolupáchateľmi komunistickej politiky teroru a zabíjania. V západnej spoločnosti boli médiá silne infiltrované komunistickým myslením, čím sa stali jedným z hlavných agentov komunizmu presadzovaním antitradičných, antimorálnych a démonických trendov. Propagujú lži a nenávisť a prilievajú olej do ohňa morálnej degenerácie. Mnoho mediálnych subjektov sa zrieklo svojej povinnosti hovoriť pravdu a&nbsp;ochraňovať morálne svedomie spoločnosti. Je veľmi dôležité, aby sme pochopili, do akého stavu sa dnešné médiá dostali, a priviedli ich späť k zodpovednosti.</p>
        <h2>1. Masová indoktrinácia v komunistických krajinách</h2>
        <p>Komunisti od samého začiatku vnímali médiá ako nástroj na vymývanie mozgov. Marx a Engels pomáhali v roku 1847 spísať text „Pravidlá Zväzu komunistov“, kde od členov vyžadovali „revolučnú energiu a horlivosť v&nbsp;propagande“.<a href="#_edn3" id="_ednref3">[3]</a> Vo svojich článkoch Marx a&nbsp;Engels často používali pojmy ako „bojisko strany“, „hlásna trúba strany“, „politické ústredie“ alebo „nástroj na ovplyvňovanie verejnej mienky“, aby tým vyjadrili požadovaný charakter a funkciu médií.</p>
        <p>Lenin používal médiá ako nástroj na propagáciu, podnecovanie a organizovanie ruskej revolúcie. Pomohol založiť oficiálne komunistické noviny Iskra a Pravda na podporu revolučnej propagandy a aktivizmu. Čoskoro potom, ako sovietska komunistická strana získala moc, použila médiá pre politickú indoktrináciu na domácej pôde a&nbsp;pre šírenie propagandy v zahraničí v snahe vylepšiť svoj obraz a&nbsp;vyvážať revolúciu.</p>
        <p>ČKS tiež považuje médiá za nástroj na kontrolu verejnej mienky a za hovorcu strany. Strana si veľmi dobre uvedomuje, že „zbrane a perá sú tým, na čo sa spolieha pri uchopení a upevňovaní moci“.<a href="#_edn4" id="_ednref4">[4]</a> Chu Čchiao-mu, tajomník Mao Ce-tunga, navrhol už v dobe Jen'anu (1937 – 1945) princíp „stranícka povaha na prvom mieste“. Stanovil, že stranícke noviny „musia vo všetkých článkoch, každej eseji, každej správe a každom bulletine presadzovať názory a stanoviská strany...“.<a href="#_edn5" id="_ednref5">[5]</a></p>
        <p>Strana po ustanovení svojej diktatúry uvalila prísnu kontrolu na médiá a neskôr aj na internet. Využíva ich ako nástroj na&nbsp;indoktrináciu Číňanov komunistickou ideológiou, na potláčanie disidentov, na zastrašovanie verejnosti a na skrývanie či skresľovania pravdy. Pracovníci v čínskych médiách sú odborníkmi na autocenzúru a musia byť stále v strehu, pretože jediná chyba môže mať neblahé následky. Cenzúra je všadeprítomná nielen na oficiálnych spravodajských kanáloch, ale aj na osobných blogoch a v online komunitách, na ktoré dohliada rozsiahla sieť internetovej polície.</p>
        <p>V Číne existuje fráza, ktorá jasne popisuje úlohu médií pod vládou ČKS: „Som strážnym psom strany, pri&nbsp;jej dverách sedím. Pohryziem každého, na koho strana ukáže, toľkokrát, koľkokrát mi strana povie.“ Nejde o preháňanie. Každé komunistické politické hnutie začína manipuláciou verejnej mienky: médiá šíria lži k&nbsp;podnecovaniu nenávisti, ktorá sa stupňuje a prerastá do násilia a zabíjania. V&nbsp;tomto smrteľnom mechanizme zohrávajú médiá kľúčovú úlohu.</p>
        <p>Počas masakru na námestí Nebeského pokoja v roku 1989 komunistická strana tvrdila, že študenti boli násilnými kriminálnikmi, a tak k potlačeniu „výtržností“ použila armádu. Po masakre tvrdila, že armáda nikoho nezastrelila a že na Námestí nebeského pokoja neboli žiadne obete.<a href="#_edn6" id="_ednref6">[6]</a> V roku 2001, v začiatkoch prenasledovania Falun Gongu, režim zinscenoval tzv. incident sebaupálenia na Námestí nebeského pokoja, aby mohol Falun Gong falošne obviniť a tým podnietiť nenávisť proti tejto duchovnej praxi v Číne a vo svete.<a href="#_edn7" id="_ednref7">[7]</a></p>
        <p>Vedúce kádre v jednotlivých výboroch na všetkých úrovniach strany prikladajú veľkú dôležitosť propagandistickej činnosti. K&nbsp;tejto úlohe sa využíva značný počet ľudí. Koncom roka 2010 pracovalo v národnom propagandistickom aparáte viac ako 1,3 milióna zamestnancov, z toho zhruba 56 tisíc v propagandistických oddeleniach na provinčnej a okresnej úrovni, 1,2 milióna v miestnych propagandistických jednotkách a 52 tisíc ľudí v ústredných jednotkách propagandy.<a href="#_edn8" id="_ednref8">[8]</a> Toto číslo nezahŕňa veľký počet zamestnancov, ktorí sú zodpovední za monitorovanie a ovplyvňovanie názorov na internete, ako je internetová polícia, moderátori, stranou kontrolovaní komentátori a ďalšie osoby v rôznych povolaniach súvisiacich so vzťahmi s&nbsp;verejnosťou.</p>
        <p>Všetky krajiny, ktorým vládnu komunistické strany, vynakladajú veľké množstvo financií na ovplyvňovanie médií. V priebehu rokov svojho pôsobenia sa komunistické štátne médiá vypracovali na výkonných hovorcov svojich totalitných pánov, ktorí používajú všetky dostupné prostriedky na to, aby ľudí oklamali a&nbsp;otrávili ich myseľ.</p>
        <h2>2. Komunistická infiltrácia v západných médiách a&nbsp;v Hollywoode</h2>
        <p>Minulé storočie bolo svedkom veľkej konfrontácie medzi slobodným svetom a komunistickým táborom. Po celú tú dobu sa komunizmus snažil infiltrovať do médií v západnom svete. Vzhľadom k mimoriadnemu vplyvu, ktorý majú americké médiá na celý svet, sa táto kapitola sústreďuje na Spojené štáty.</p>
        <p>Potom čo sa sovietsky režim chopil moci v Rusku, pokúšal sa ovplyvniť verejnú mienku na Západe. Vyslal svojich agentov, aby sa infiltrovali do západných médií a nalákali miestnych komunistických sympatizantov. Režim týchto ľudí úspešne využíval k tomu, aby pomáhali vylepšiť obraz Sovietskeho zväzu a aby tak ukryli brutalitu komunistickej vlády. Sovietske propagandistické úsilie ovplyvnilo veľké množstvo obyvateľov na Západe, a dokonca ovplyvnilo aj vládnu politiku týchto krajín v prospech Sovietskeho zväzu.</p>
        <p>Ukázalo sa, že sovietska KGB používala v Spojených štátoch svojich agentov, ktorí pracovali priamo v prestížnych amerických médiách. Patrili k nim napríklad John Scott, Richard Lauterbach a Stephen Laird z časopisu <em>Time,</em> ktorí využívali svoje pozície k tomu, aby nadviazali kontakt s politikmi, celebritami a hlavami štátu. Okrem toho, že zhromažďovali širokú paletu spravodajských informácií, ovplyvňovali dôležité rozhodnutia v otázkach politiky, ekonomiky, diplomacie, vojny a pod. Ďalší redaktor <em>Time</em> a&nbsp;sovietsky špión, Whittaker Chambers, neskôr od Sovietov zbehol a&nbsp;napísal knihu <em>Svedok </em>(Witness), kde do detailov popisuje podvratné komunistické taktiky v Spojených štátoch.<a href="#_edn9" id="_ednref9">[9]</a></p>
        <p>Walter Duranty, moskovský korešpondent <em>The New York Times</em>, získal v roku 1932 Pulitzerovu cenu za sériu reportáží o&nbsp;Sovietskom zväze. Bývalý americký komunista Jay Lovestone a&nbsp;prominentný novinár Joseph Alsop však veria, že Duranty pôsobil ako sovietsky agent.<a href="#_edn10" id="_ednref10">[10]</a> Počas hladomoru v rokoch 1932 – 1933, ktorý spustošil Ukrajinu a ďalšie oblasti Sovietskeho zväzu, Duranty poprel, že by nejaký hladomor existoval, nieto ešte že by milióny ľudí zomreli hladom. Tvrdil, že „akákoľvek správa, že Sovietsky zväz trpí v&nbsp;súčasnosti hladomorom, je prehnaná a škodlivá propaganda“.<a href="#_edn11" id="_ednref11">[11]</a> Slávny britský historik a uznávaný odborník na históriu Sovietskeho zväzu, Robert Conquest, opísal vo svojej známej knihe <em>Úroda žiaľu: Sovietska kolektivizácia a násilný hladomor</em> (The Harvest of Sorrow: Soviet Collectivization and the Terror-famine) dôsledky nepravdivých Durantyho správ takto: „Ak Duranty ako najslávnejší novinár v najznámejších novinách tej doby poprel existenciu hladomoru, bralo sa to ako nemenná pravda. Nielenže oklamal čitateľa <em>New York Times</em>, ale vzhľadom k prestíži týchto novín ovplyvnil tiež to, ako na Stalina a sovietsky režim pozeralo nespočetné množstvo ľudí. Durantyho vplyv nepochybne zapôsobil aj na novozvoleného prezidenta [Franklina D.] Roosevelta a zohral rolu v jeho uznaní sovietskeho komunistického režimu.“<a href="#_edn12" id="_ednref12">[12]</a></p>
        <p>Hollywood bol takisto infiltrovaný komunistickými a&nbsp;ľavicovými myšlienkami. Nemecký komunista a člen Tretej internacionály Willi Münzenberg si po svojom príchode do Spojených štátov uvedomil, že americký filmový priemysel sa dá využiť ako nástroj k šíreniu propagandy, čím by sa realizoval Leninov koncept rozvoja a produkcie filmov. Vybral si &nbsp;asistenta, ktorému dôveroval, Otta Katza, a svojho spolupracovníka Louise Gibartiho, aby sa infiltrovali do filmového odvetvia. Katz bol vysoko úspešný. Podarilo sa mu preniknúť do spoločenských kruhov hollywoodskej smotánky a čoskoro potom založil v Hollywoode pobočku komunistickej strany – Hollywoodsku protinacistickú ligu.</p>
        <p>A tak sa vplyv Sovietskeho zväzu začal prejavovať aj vo filmovom priemysle. Mnoho filmárov tej doby idealizovalo Sovietov a&nbsp;počas druhej svetovej vojny, kedy Spojené štáty a Sovietsky zväz nakrátko spojili sily v boji proti nacistickému Nemecku, tieto nálady len vzrástli. Jeden slávny americký dramatik napríklad tvrdil, že nemecká invázia do Sovietskeho zväzu bola „útokom na našu vlasť“.<a href="#_edn13" id="_ednref13">[13]</a> Vo filme <em>Misia do Moskvy</em> z roku 1943, ktorého cieľom bolo posilniť podporu Sovietskeho zväzu u verejnosti, zaznie veta, v ktorej je Sovietsky zväz vyobrazený ako krajina, ktorá je založená na rovnakých základných princípoch ako Spojené štáty.<a href="#_edn14" id="_ednref14">[14]</a></p>
        <p>Z ľavicových médií a novinárov v slobodnom svete mal okrem bývalého Sovietskeho zväzu ohromný prospech tiež čínsky komunistický režim. Medzi ústredné postavy propagujúce komunizmus vo svojej dobe patrili významní ľavicoví americkí novinári ako Edgar Snow, Agnes Smedleyová, či Anna Louise Strongová. Edgar Snow v knihe <em>Červená hviezda nad Čínou </em>(Red Star Over China) vykreslil žiarivý obraz Mao Ce-tunga a ďalších vodcov ČKS a zároveň pred západnými čitateľmi skrýval zločiny komunistov a zápornú povahu komunizmu. Mao vyhlásil: „Snow je prvý človek, ktorý vydláždil cestu priateľským vzťahom, ktoré sú potrebné na vytvorenie jednotného frontu.“<a href="#_edn15" id="_ednref15">[15]</a> Agnes Smedleyová napísala sériu článkov a kníh, ktoré lichotili ČKS a jej vodcom. V sovietskych archívoch existujú silné dôkazy o tom, že bola agentkou Kominterny, ktorá pracovala na podpore ozbrojenej revolúcie v Indii a&nbsp;zhromažďovala informácie pre Sovietov.<a href="#_edn16" id="_ednref16">[16]</a> Anna Louise Strongová bola tiež obdivovateľkou čínskeho komunistického hnutia. ČKS všetkých týchto troch Američanov vyznamenala tým, že na počesť ich „záslužnej služby“ vydala poštové známky s&nbsp;ich podobizňami.</p>
        <h2>3. Ľavicová predpojatosť ľudí v&nbsp;médiách</h2>
        <p>Väčšina Američanov tvrdí, že médiá majú stranícku predpojatosť. Prieskum spoločnosti Gallup z roku 2017 ukázal, že 64&nbsp;% opýtaných sa domnieva, že médiá uprednostňujú demokratov, pričom 22&nbsp;% si myslí, že médiá uprednostňujú republikánov.<a href="#_edn17" id="_ednref17">[17]</a> Vzniká teda otázka, ako môže v takomto konkurenčnom prostredí, ako sú médiá, existovať takto veľká predpojatosť?</p>
        <p>Aj keď reportéri a redaktori majú svoje vlastné názory na politiku a spoločnosť, nemalo by sa to v ich správach odrážať. Vzhľadom k tomu, že objektivita a neutralita sú základnými princípmi novinárskej etiky, správy by nemali byť zafarbené osobným názorom. Podľa bežných trhových princípov by mala byť akákoľvek zaujatosť kompenzovaná vznikom nových, neutrálnejších konkurentov.</p>
        <p>Skutočnosť je však zložitejšia. Americký politológ Tim Groseclose v roku 2012 v knihe <em>Ľavicový obrat: Ako liberálna predpojatosť médií kriví myseľ Američanov</em> (Left Turn: How Liberal Media Bias Distorts the American Mind) analyzoval prísnymi vedeckými metódami politické smerovanie veľkých amerických médií. Jeho zistenia ukázali, že americké médiá v priemere silne tiahnu k&nbsp;liberalizmu a progresivizmu – oveľa viac doľava, než ako volí typický občan. „Mainstreamové“ médiá sú vychýlené doľava dokonca ešte viac, než je tento priemer.<a href="#_edn18" id="_ednref18">[18]</a> Kniha vysvetľuje, že väčšina pracovníkov v médiách sú liberáli, čo objektívne povedané, vyvíja tlak na konzervatívnejších žurnalistov. Na ten malý počet tradicionalistov pracujúcich v liberálnych mediálnych spoločnostiach sa podľa Grosecloseho nazerá ako na „trochu zlých alebo nehumánnych“. Dokonca aj keď sú si schopní svoju pozíciu v zamestnaní udržať, neodvážia sa k svojim politickým názorom verejne hlásiť, nieto aby svoj konzervatívny pohľad presadzovali v tlači alebo v televízii.<a href="#_edn19" id="_ednref19">[19]</a> Podľa prieskumu ABC <em>News a Washington Post</em> z roku 2013 sa 28&nbsp;% novinárov v Spojených štátoch označuje za demokratov v porovnaní so sotva 7,1&nbsp;% tých, ktorí sa hlásia k republikánom.<a href="#_edn20" id="_ednref20">[20]</a></p>
        <p>Komunita ľudí v médiách vylučuje názory, ktoré nezodpovedajú ich liberálnej predpojatosti, čím vytvára politické názorové bubliny. Jednotlivci v tejto komunite sa považujú za súcitnú a inteligentnú elitu v popredí spoločenského rozvoja a zároveň sa pozerajú na obyčajných občanov ako na tvrdohlavých hlupáčikov. Táto ľavicová predpojatosť odrádza študentov s konzervatívnymi názormi, aby si vybrali žurnalistiku za svoj hlavný odbor, alebo aby sa po ukončení štúdiá uchádzali o prácu v médiách.</p>
        <p>57 zo 100 najväčších novín – o celkovom náklade 13 miliónov výtlačkov – v prezidentskej kampani v USA v roku 2016 otvorene podporovalo demokratickú kandidátku. Iba dvoje noviny z hornej stovky, s nákladom 300 tisíc výtlačkov, podporovali republikánskeho kandidáta.<a href="#_edn21" id="_ednref21">[21]</a> Mainstreamové médiá však nemusia nutne predstavovať názory hlavného spoločenského prúdu. Potvrdil to napríklad prieskum spoločnosti Gallup z roku 2016, podľa ktorého bolo 36&nbsp;% amerických občanov konzervatívnych, zatiaľ čo liberáli tvorili len niečo cez 25&nbsp;%.<a href="#_edn22" id="_ednref22">[22]</a> To znamená, že ak by médiá verne odrážali názory väčšiny občanov, potom by sa nemali ako celok prikláňať k ľavici.</p>
        <p>Ľavicová predpojatosť médií očividne nie je výsledkom vôle ľudu, ale vychádza z nátlaku zákulisnej politickej agendy zameranej na posun celého národa doľava. Rozdiel medzi konzervatívcami a&nbsp;liberálmi v roku 1996 predstavoval 22&nbsp;%, v roku 2014 to bolo 14&nbsp;% a v roku 2016 už len 11&nbsp;%. Podiel konzervatívcov zostal stabilný, ale mnoho voličov uprostred sa priklonilo na stranu ľavice. Hlavné médiá majú v&nbsp;tejto demografickej transformácii nepopierateľnú úlohu, čo zase posilňuje ideologickú predpojatosť médií.</p>
        <p>Prečo sa médiá tak veľmi prikláňajú k ľavici? V 60. rokoch bola krajina silne ovplyvnená komunistickou ideológiou a radikálne ľavicové spoločenské hnutie vzalo Spojené štáty útokom. Študentskí radikáli toho obdobia neskôr vstúpili do médií, akademickej obce, vyšších tried spoločnosti, vládnych agentúr a na umeleckú scénu, čím ovládli verejný diskurz. Dnes je väčšina univerzitných profesorov zameraná ľavicovo a fakulty žurnalistiky a literatúry vychovali niekoľko generácií absolventov v rovnakom duchu. Ľudia v médiách nedostávajú vysoké mzdy. Tým, čo ich v ich profesii poháňa, je často idealizmus a pocit zmysluplnosti. Tento idealizmus sa stal základným nástrojom na pretvorenie médií na základňu ľavicových operácií.</p>
        <p>Spolu so spravodajskými médiami je obliehaný aj filmový priemysel. Hollywood sa stal baštou ľavicovej propagandy. S využitím sofistikovanej produkcie a dômyselných rozprávačských techník presadzujú ľavicoví producenti ľavicové ideológie, ktoré sa rozšírili do celého sveta. Zdá sa, akoby hlavnými témami hollywoodskych filmov bolo ohováranie kapitalizmu a zdôrazňovanie triedneho konfliktu, zatiaľ čo sa vychvaľuje nemorálne správanie alebo protiamerické postoje.</p>
        <p>Autor Ben Shapiro robil v rámci práce na svojej knihe <em>Propaganda v hlavnom vysielacom čase: Pravdivý hollywoodsky príbeh o tom, ako ľavica ovládla vašu televíziu</em> (Primetime Propaganda: The True Hollywood Story of How the Left Took Over Your TV) rozhovory s filmovými hviezdami a producentmi v Hollywoode. Podľa Shapira istý slávny producent povedal, že v jeho profesii je „na 100 % dominantný liberalizmus“ a že každý, kto to popiera, buď žartuje, alebo nehovorí pravdu. Na otázku, či iné politické stanovisko môže človeku brániť v kariére vo filmovom priemysle, odpovedal: „Rozhodne.“ Ďalší slávny producent bez servítky pripustil, že Hollywood prostredníctvom svojich programov predáva liberálne politické názory. „Práve teraz existuje len jedna perspektíva. A&nbsp;je to veľmi progresívna perspektíva.“<a href="#_edn23" id="_ednref23">[23]</a> Producent televízneho seriálu o kriminálnej polícii pripustil, že úmyselne ukazuje viac bielych ako zločincov, pretože „nechce prispievať k negatívnym stereotypom“.<a href="#_edn24" id="_ednref24">[24]</a></p>
        <p>Shapiro tvrdí, že rodinkárstvo v Hollywoode je skôr ideologického než rodinného charakteru: priatelia obsadia vo filmoch priateľov, ktorí majú rovnaké ideologické názory. Otvorenosť, s akou ľudia v&nbsp;Hollywoode priznávajú diskrimináciu konzervatívnych názorov, ktorá funguje vo filmovom priemysle, je šokujúca. Tí, ktorí hovoria o tolerancii a rôznorodosti, nie sú ani trochu tolerantní keď príde na rešpektovanie rôznorodých ideológií.<a href="#_edn25" id="_ednref25">[25]</a></p>
        <h2>4. Prevaha liberalizmu a progresivizmu v&nbsp;médiách</h2>
        <p>Walter Williams, zakladateľ žurnalistického vzdelávania a&nbsp;prvej žurnalistickej školy na svete pri Missourskej univerzite, vytvoril v roku 1914 tzv. Novinárske krédo (The Journalist Creed). Definoval novinárčinu ako nezávislú profesiu, ktorá má v úcte Boha a&nbsp;váži si ľudstvo. Novinári by nemali byť „zasiahnutí pýchou kvôli svojmu názoru a nemali by lačnieť po moci“. Musia venovať pozornosť detailom, uplatňovať sebaovládanie a trpezlivosť, byť nebojácni a&nbsp;musia mať neustály rešpekt voči svojim čitateľom.<a href="#_edn26" id="_ednref26">[26]</a> Začiatkom 70. rokov však v médiách začal prevládať progresivizmus. Objektivitu nahradilo presadzovanie vplyvu. Nestrannosť nahradili liberalizmus a progresivizmus.</p>
        <p>Samuel Robert Lichte v roku 1986 v knihe <em>Mediálna elita</em> (The Media Elite) podotkol, že reportéri majú tendenciu svojimi názormi a&nbsp;doterajším vzdelaním prifarbovať svoje správy o kontroverzných otázkach. Väčšina ľudí v redakcii sú liberáli, čo posunulo spravodajstvo v prospech liberálnej politiky.<a href="#_edn27" id="_ednref27">[27]</a> Jim A. Kuypers vo svojom výskume dvestoročného vývoja americkej žurnalistiky dospel k záveru, že dnešné hlavné médiá sú liberálne a progresívne ako vo svojej štruktúre, tak aj vo svojich správach. Citoval liberálneho redaktora veľkých novín, ktorý vyhlásil: „Príliš často sa chválime svojim liberalizmom. Netolerujeme iné životné štýly a názory. Neváhame povedať, že ak u nás chcete pracovať, musíte byť rovnakí ako my a musíte byť liberálni a progresívni.“<a href="#_edn28" id="_ednref28">[28]</a> V jednom z komentárov, ktoré publikoval denník <em>The Wall Street Journal </em>v roku 2001, bývalý reportér CBS Bernard Goldberg napísal, že moderátori hlavných médií sú takí predpojatí, že už ani sami „nevedia, čo to liberálna predpojatosť vlastne je“.<a href="#_edn29" id="_ednref29">[29]</a></p>
        <p>Napriek prieskumom ukazujúcim, že Američania sú si vedomí predpojatosti médií, je veľa ľudí stále presvedčených, že správy sa píšu objektívne a komplexne a že to, čo sa cituje, je seriózna odborná analýza založená na informáciách od spoľahlivého zdroja. Ľavicové médiá využívajú dôveru svojich čitateľov a divákov na to, aby im vštepovali svoj ideologický pohľad na svet. Slobodné západné spoločnosti tradične zdôrazňujú potrebu pravdivých, objektívnych a spravodlivých médií. Preto ľavicové médiá spravidla nešíria falošné správy, ktorými by verejnosť klamali priamo. Ako popíšeme nižšie, ich metódy sú subtílnejšie a komplikovanejšie.</p>
        <h3>Selektívne pokrytie</h3>
        <p>Každý deň sa na celom svete objavujú desiatky tisíc zaujímavých udalostí. Ktorým udalostiam sa však dostane pozornosti, alebo ktoré naopak ticho zmiznú z očí verejnosti, je takmer výhradne určené tým, čo sa médiá rozhodnú pokryť.</p>
        <p>Selektívne pokrytie možno rozdeliť do troch kategórií. Po prvé, udalosti sa vyberajú iba alebo primárne na základe toho, ako veľmi dokážu pomôcť čitateľom prijať ideologický postoj ľavice. Po druhé, miesto podrobného prinášania správ o kontexte udalosti sa uvádzajú len aspekty, ktoré podporujú ľavicový pohľad. A nakoniec, médiá majú tendenciu dávať väčší priestor tým, ktorí sa priklonia doľava alebo ktorých stanovisko sa zhoduje s ľavicou, zatiaľ čo iné organizácie a jednotlivcov zoslabujú. Groseclose a Jeffrey Milio napísali v&nbsp;roku 2005 prácu s názvom <em>Miera predpojatosti médií</em> (A Measure of Media Bias), kde uvádzajú: „Na každý hriech zo spáchania ... ako veríme, pripadnú stovky, a možno tisíce, hriechov z opomenutia, kedy si novinár vybral fakty alebo príbehy, v ktorých sa spravidla zmieňuje iba jedna strana politického spektra.“<a href="#_edn30" id="_ednref30">[30]</a></p>
        <p>Snáď najočividnejšími príkladmi selektívneho pokrytia je nedostatok spravodajstva o najväčšom prenasledovaní veriacich v&nbsp;súčasnej histórii. Čína prenasleduje od roku 1999 členov duchovnej praxe Falun Gong, ktorá ctí univerzálne princípy pravdivosti, súcitu a znášanlivosti. Tento útlak sa už vyše dvadsať rokov priamo dotýka stoviek miliónov ľudí v najľudnatejšej krajine sveta a miera brutality, s&nbsp;ktorou sa prenasledovanie odohráva, je neuveriteľná. Napriek tomu, keď sa pozrieme na rozsah a vážnosť skutočných udalostí, ku ktorým v Číne dochádza, je pokrytie tejto veci západnými médiami neúmerne slabé. Väčšina veľkých mediálnych spoločností je ovplyvnená politickou mocou ČKS a uplatňuje autocenzúru alebo zostáva potichu aj napriek obriemu útoku čínskeho režimu na slobodu presvedčenia a&nbsp;ústredné hodnoty ľudskej civilizácie. Niektoré spoločnosti dokonca pomáhajú režimu šíriť jeho dezinformácie o&nbsp;Falun Gongu.</p>
        <p>V rovnakej dobe sa vynoril trend, ktorý komunizmu oponuje a hlása návrat k tradícii. Vzniklo hnutie Tuidang („Vystúp zo strany“), ktoré má pre budúcnosť Číny a sveta ohromnú dôležitosť. Do mája 2020 vystúpilo z ČKS a jej pridružených organizácií vyše 350 miliónov ľudí, a napriek tomu zmienku o tomto fenoméne v západných médiách takmer nenájdete.</p>
        <h3>Nastoľovanie tém</h3>
        <p>V 60. rokoch mediálni výskumníci prišli s vplyvnou teóriou, podľa ktorej je funkciou médií určiť, ktoré témy sú vhodné na diskusiu. Americký politológ Bernard Cohn situáciu trefne vyjadril týmito slovami: „Tlač nemusí byť vždy úspešná v tom, keď ľuďom hovorí, čo si majú myslieť, ale je úžasne úspešná v určovaní toho, o čom majú jej čitatelia premýšľať.“<a href="#_edn31" id="_ednref31">[31]</a> To znamená, že tlač môže určiť dôležitosť udalostí počtom správ a následných správ, ktoré danú udalosť pokryjú, zatiaľ čo rovnaké alebo dôležitejšie otázky môže riešiť viac súhrnne, alebo vôbec. Zoberme si ako príklad problematiku práv transgender osôb. Hoci sa týka len veľmi malej časti obyvateľstva, stala sa ústredným bodom diskusie a je príkladom toho, ako médiá úspešne nastolili svoju agendu. Ďalšou dôležitou otázkou vo verejnom diskurze sa stalo globálne otepľovanie, čo je výsledkom dlhoročného sprisahania medzi médiami a inými politickými silami.</p>
        <p>Veľa progresívnych myšlienok, ako sú takzvaná sociálna spravodlivosť, rovnoprávnosť a feminizmus, si našli cestu do hlavných správ, zatiaľ čo zločiny komunizmu sú cenzurované. Bývalý hovorca americkej snemovne, Newt Gingrich, napísal v roku 2018: „Akademická ľavica a jej spravodajské médiá a&nbsp;hollywoodski pätolizači sa odmietajú postaviť otrasnej histórii nekonečnej nehumánnosti marxizmu.“<a href="#_edn32" id="_ednref32">[32]</a></p>
        <h3>Rámcovanie</h3>
        <p>Existuje veľa problémov, ktoré sú príliš veľké na to, aby sa dali ignorovať, a preto médiá používajú metódu rámcovania, aby ovplyvnili informačné prostredie. Hnutie za sexuálne oslobodenie a politika sociálneho zabezpečenia v 60. rokoch viedli k rozpadu rodín, nárastu chudoby a rastúcej zločinnosti. Avšak ľavica používa médiá a Hollywood, aby vytvorila obraz silnej a nezávislej slobodnej matky, zakrývajúc skutočné spoločenské problémy, ktoré stoja za týmto javom. Nájdu odborníkov, ktorí prisudzujú zlé finančné a spoločenské postavenie menšín systémovej diskriminácii, a tým zastrú pravé príčiny, z&nbsp;ktorých mnohé majú korene práve v komunizme. Prevaha takýchto názorov je do značnej miery dôsledkom tajnej dohody medzi médiami a&nbsp;určitými politickými silami.</p>
        <p>Metóda rámcovania je zrejmá hlavne na fenoméne príbehov, pre ktoré sa hľadajú fakty. Pri objektívnom podávaní správ autor zhŕňa fakty do príbehu. Reportéri a redaktori, ktorí majú predpojaté názory na danú otázku, však spracujú fakty tak, aby zapadali do príbehu, ktorý potom ich predpojatosť potvrdzuje.</p>
        <h3>Použitie politickej korektnosti pri presadzovaní autocenzúry</h3>
        <p>Politická korektnosť, ako účinná zbraň komunizmu, je v&nbsp;médiách všadeprítomná. Mnoho mediálnych výstupov má či už v písanej alebo implicitnej podobe smernice o politickej korektnosti, ktorá ovplyvňuje to, čo sa má alebo nemá písať a ako sa to má podať. Z dôvodu právnych predpisov týkajúcich sa „trestných činov z&nbsp;nenávisti“ sa v&nbsp;niektorých európskych krajinách mnoho miestnych médií neodváži podávať správy o zločinoch spáchaných prisťahovalcami, hoci sa tieto zločiny stali závažnou sociálnou otázkou a&nbsp;ohrozujú domácu bezpečnosť v týchto krajinách. Americké mediálne organizácie tiež vykonávajú autocenzúru. Keď informujú o zločinoch, často vynechávajú imigračný status páchateľov. Západné médiá spoločne s ľavicovými politickými skupinami a akademikmi vytvorili lexikón politicky korektného jazyka. V médiách sa používa tak často, že sa verejnosti zapísal hlboko do podvedomia a ovplyvňuje tak verejnosť na podprahovej úrovni.</p>
        <h2>Nálepkovanie konzervatívnych zdrojov, aby sa neutralizoval ich vplyv</h2>
        <p>Aby liberálne médiá vytvorili dojem vyváženého spravodajstva, nemajú inú možnosť ako podať správu o názoroch konzervatívcov alebo konzervatívnych think-tankov. Médiá pri citovaní týchto zdrojov zvyčajne používajú označenie „konzervatívny“, „pravicový“ alebo „nábožensky pravicový“, čo má jemne naznačiť, že ich názory sú predpojaté alebo nie sú dôveryhodné kvôli prostému faktu, že sú konzervatívci. Médiá zvyčajne pri citovaní liberálov alebo liberálnych think-tankov používajú neutrálne tituly ako „vedec“ alebo „expert“, ktoré naznačujú, že tieto názory sú nestranné, objektívne, racionálne a dôveryhodné.</p>
        <p>Hneď ako médiá potvrdia názor ľavice, prejaví sa to vo všetkých aspektoch spoločnosti. Správa <em>New York Times</em> z októbra 2008 nazvaná „V divadle dominuje liberálny pohľad“, začína vetou: „Počas tejto volebnej sezóny môžu návštevníci divadiel v New Yorku vidieť desiatku vyložene politických hier o Iraku, korupcii vo Washingtone, feminizme alebo prisťahovalectve; čo neuvidia, sú akékoľvek hry s konzervatívnou perspektívou.“<a href="#_edn33" id="_ednref33">[33]</a></p>
        <p>Politické zafarbenie médií sa odráža aj v tom, ako pokrývajú demokratické procesy. O liberálnych kandidátoch sa píše pozitívne, zatiaľ čo kandidátom, ktorí sa hlásia k tradičným názorom, sa dostáva väčšej kritiky. Takéto správy a „odborné“ analýzy majú veľký vplyv na hlasovanie voličov.</p>
        <p>Groseclose zistil, že viac ako 90&nbsp;% reportérov vo Washingtone, D. C., hlasovalo za demokratov. Podľa Grosecloseho výpočtu mediálna predpojatosť voči demokratickým kandidátom v typických voľbách zvyšuje počet hlasov o 8 až 10 percentuálnych bodov. Ak by táto mediálna predpojatosť neexistovala, John McCain by napríklad vo voľbách namiesto prehry 53&nbsp;% ku 46&nbsp;% porazil Baracka Obamu pomerom 56&nbsp;% ku 42&nbsp;%.<a href="#_edn34" id="_ednref34">[34]</a></p>
        <h2>5. Filmový priemysel: prvá línia boja proti tradícii</h2>
        <p>Hollywood ako medzinárodný symbol americkej kultúry slúži na šírenie a umocnenie amerických hodnôt vo svete. Stal sa však tiež nástrojom, ktorý celé ľudstvo vystavuje deformovaným, antitradičným hodnotám.</p>
        <p>Dnes je pre väčšinu Američanov ťažké si predstaviť, že v 30. a 40. rokoch sa rodiny nemuseli obávať negatívneho vplyvu filmov na deti. Bolo to dané tým, že filmový priemysel v tej dobe dodržiaval prísne morálne predpisy. V roku 1930, so silnou podporou zo strany cirkvi, zaviedol filmový priemysel tzv. Produkčný kódex (tiež nazývaný Haysov kódex). Jeho prvým princípom bolo, že by nemal vzniknúť žiadny film, ktorý by znižoval morálne štandardy divákov. Ľudia by vo filmoch nikdy nemali byť vedení k&nbsp;sympatizovaniu s kriminalitou, so zločinom, zlom alebo hriechom. Zásada Produkčného kódexu ohľadom sexu bolo zachovávať posvätnosť manželstva: z filmov by nemalo byť zrejmé, že nízke formy sexuálnych vzťahov sú prijateľnou normou. Téma cudzoložstva, ktorá je niekedy nutná ako materiál pre zápletku, nesmie byť ospravedlňovaná, opisovaná atraktívne alebo výslovne podporovaná.</p>
        <p>Od 50. rokov však sexuálne oslobodenie spôsobilo kultúrne a&nbsp;morálne otrasy. Vzostup televízie v amerických domácnostiach vyvolal obrovský tlak na trh a rivalitu medzi filmovými producentmi. Hollywood začal Produkčný kódex stále viac ignorovať a prestal uplatňovať zdržanlivosť. Napríklad <em>Lolita</em> (1962), film na motívy rovnomenného románu, zobrazuje cudzoložný a pedofilný vzťah medzi mužom a jeho nevlastnou neplnoletou dcérou. Hoci film dostal vo svojej dobe negatívne i pozitívne recenzie, dnes si <em>Lolita</em> na filmovom a televíznom recenznom portáli Rotten Tomatoes drží 91&nbsp;% hodnotenie. To odráža radikálny obrat v spoločenskej morálke, ku ktorému došlo v posledných desaťročiach.</p>
        <p>Hnutie kontrakultúry na konci 60. rokov znamenalo zrútenie tradičnej morálky a poriadku v&nbsp;produkcii Hollywoodu. Niekoľko ikonických filmov, ktoré zobrazujú témy vzbury, odráža rastúci úpadok v americkom filmovom priemysle. Ako sme uviedli v predchádzajúcich kapitolách, kľúčovou taktikou komunizmu je zobraziť zločinné správanie v ušľachtilom alebo spravodlivom svetle. <em>Bonnie a Clyde</em> je kriminálny film z roku 1967 založený na skutočnom príbehu dvoch zlodejov z obdobia svetovej hospodárskej krízy. Počas krízy mnohé rodiny prišli o strechu nad hlavou potom, čo im banky zhabali dom. Protagonisti vo filme nad tým vyjadrujú oprávnený hnev a sú zobrazení ako bojovníci proti spoločenskej nespravodlivosti, keď sa dopúšťajú bankových lúpeží a vrážd. Film, ktorý ako jeden z prvých obsahuje prvky explicitného násilia, je podávaný v štýle Robina Hooda. Zločinecký pár hral sympatický muž a krásna žena, &nbsp;ktorí boli vyobrazení ako ľudia s prirodzeným zmyslom pre spravodlivosť. Policajti boli oproti tomu vykreslení skôr ako nekompetentné bábky než ako ochrancovia práva a poriadku. Smrť Bonnie a Clydea, ktorých v závere filmu vláka polícia do pasce, mal hlboký vplyv na dospievajúcich divákov. Obaja boli blahoslavení ako mučeníci, akoby sa obetovali pre niečo väčšie.</p>
        <p>Téma zločinu a násilia zobrazená vo filme šokovala väčšinovú americkú spoločnosť, no napriek tomu našla veľkú odozvu medzi rebelujúcimi študentmi. Mladí začali kopírovať ich štýl oblečenia, reči a pohŕdanie tradíciou a zvyklosťami. Dokonca sa snažili napodobniť spôsob, akým táto dvojica zomrela.<a href="#_edn35" id="_ednref35">[35]</a> Hoci prvotná recenzia filmu v časopise <em>Time</em> ho hodnotila ako nemravný a&nbsp;plný dier v zápletke, hlavné hviezdy snímky sa za niekoľko mesiacov objavili na obálke časopisu a v článku o filme bolo uvedené: „<em>Bonnie a Clyde</em> je nielen hitom desaťročia, ale podľa rastúcej zhody medzi divákmi a kritikmi tiež najlepším filmom roka.“<a href="#_edn36" id="_ednref36">[36]</a> Jeden recenzent ľavicovej publikácie napísal článok, v ktorom porovnával Bonnie a&nbsp;Clyde s kubánskym vodcom partizánov E. Che Guevarom a vietkongským teroristom Nguyễn Văn Trỗi-om.<a href="#_edn37" id="_ednref37">[37]</a> Istá radikálna študentská organizácia tvrdila: „Nie sme potenciálni Bonnie a&nbsp;Clyde-ovia, my sme skutoční Bonnie a&nbsp;Clyde-ovia.“<a href="#_edn38" id="_ednref38">[38]</a> Popri prikrášľovaní zločinu zobrazoval film <em>Bonnie a Clyde</em> bezprecedentnú mieru sexuality. Film si však napriek tomu získal chválu kritikov, bol nominovaný na desať Oscarov a v dvoch kategóriách vyhral. Hollywood sa odklonil od svojich tradičných princípov.</p>
        <p>Film <em>Absolvent,</em> ktorý sa objavil na plátnach kín koncom roka 1967, odrážal vnútornú úzkosť a konflikty vysokoškolských študentov vo vtedajšom období. Snímka opisuje osamelého absolventa vysokej školy, ktorý sa ocitol na životnej križovatke. Tradičné hodnoty otcovej generácie sú vo filme vykreslené ako nudné a pokrytecké. Namiesto toho, aby sa zapojil do hlavného prúdu americkej spoločnosti, rozhodne sa pre vzťah so staršou vydatou ženou, pričom sa zamiluje do jej dcéry, ktorá aféru objaví. Na svadobnom obrade dcéry a iného mladého muža vtrhne protagonista do kostola a&nbsp;spoločne s čerstvo vydatou ženou utečú. Absolvent, ktorý ukazuje zmes mladistvej vzbury, nekontrolovaného libida a&nbsp;ďalších tém, odráža zmätené a antitradične naladené prostredie rebelskej mladosti. Film bol fenomenálne úspešný a okamžite sa stal kasovým trhákom, získal sedem nominácií na Oscara a jedného vyhral.</p>
        <p>Filmy ako <em>Bonnie a Clyde</em> a <em>Absolvent</em> odštartovali éru moderného Hollywoodu. Na konci roka 1968 bol Produkčný kódex nahradený modernou klasifikáciou filmov (do kategórii podľa stupňa spoločenskej škodlivosti, pozn. prekl.). To v praxi znamená, že je možné premietať filmy s akýmkoľvek obsahom, ak sú správne klasifikované. Toto značne uvoľnilo morálnu zdržanlivosť zábavného priemyslu a rozmazalo normy správneho a nesprávneho. Takto tvorcovia v oblasti zábavy a médií oddelili morálku od svojich výtvorov a dali voľný priechod vyobrazovaniu nemravného a&nbsp;zlého obsahu. Degenerovaná zábava zahltila publikum lacnou, vzrušujúcou a ľahko dostupnou stimuláciou. Producenti sa medzitým poddali svojej chamtivosti a túžbe po ohromných komerčných ziskoch.</p>
        <p>Film je špeciálne médium, ktoré má schopnosť vytvárať príťažlivú atmosféru a realistické osobnosti. Skúsený režisér dokáže na mnohých úrovniach zmeniť názor divákov, najmä tých mladých a ovplyvniteľných, a dokáže tvarovať ich pocity a pohľad na svet. Jeden známy filmový producent raz povedal: „Dokumentárne filmy presviedčajú už presvedčených. Filmová fikcia presviedča tých doteraz nepresvedčených.“<a href="#_edn39" id="_ednref39">[39]</a> Inými slovami, dokumentárne filmy posilňujú hodnoty, ktoré už diváci majú, zatiaľ čo hrané snímky využívajú fascinujúce príbehy, aby nič netušiacim divákom vštepil nový súbor hodnôt.</p>
        <p>Producent a hlavný protagonista filmu <em>Bonnie a Clyde</em> je podporovateľom socializmu. Jeho historická dráma z roku 1981 <em>Červení</em> získala Oscara a Zlatý glóbus. Na vrchole studenej vojny zmenili <em>Červení</em> ustálenú predstavu radikálneho komunistu na pohodového a&nbsp;sympatického idealistu.<a href="#_edn40" id="_ednref40">[40]</a> Vo svojom ďalšom filme nominovanom na Oscara, <em>Škandál Bulworth,</em> hrá liberálneho kandidáta do Senátu, ktorý berie drogy, rapuje o socialistickom zdravotníctve a&nbsp;triednej nerovnoprávnosti, zúri nad korporátnou Amerikou a najme si vraha, aby ho zabil, čím by jeho dcéra získala peniaze z jeho životného poistenia.<a href="#_edn41" id="_ednref41">[41]</a> Tento film bol taký úspešný, že ho niektorí nabádali, aby kandidoval na prezidenta Spojených štátov.</p>
        <p>Po zavedení systému klasifikácie filmov začal Hollywood chrliť filmy, ktoré v kladnom svetle opisujú zdegenerované správanie ako je promiskuita, násilie, drogy a organizovaný zločin. Jedna štúdia zistila, že až 58&nbsp;% snímok vzniknutých medzi rokmi 1968 až 2005 bolo označených ratingom R (obdoba mládeži neprístupných filmov, pozn. prekl.).<a href="#_edn42" id="_ednref42">[42]</a> Jeden z prvých takých filmov, <em>Bezstarostná jazda</em>, sa stal okamžite hitom, ktorý prispel k popularite užívanie drog. Film sleduje dobrodružstvo dvoch motorkárov, priaznivcov hippies, ktorí obchodujú s kokaínom, oddávajú sa „voľnej láske“ v komunite, navštívia nevestinec a berú halucinogénne drogy. Pri natáčaní filmu sa užívali skutočné drogy. Životný štýl týchto protagonistov antisociálneho blahobytu bez konvenčných hodnôt sa stal snom mnohých mladých ľudí. Režisér priznal: „Problém s kokaínom v Spojených štátoch vznikol naozaj kvôli mne. Pred <em>Bezstarostnou jazdou</em> nebol na uliciach žiadny kokaín. Po <em>Bezstarostnej jazde</em> bol všade.“<a href="#_edn43" id="_ednref43">[43]</a></p>
        <p>Americký vedec Victor B. Cline vykonal analýzu 37 filmov, ktoré boli v 70. rokoch uvedené v Salt Lake City. Zistil, že 57&nbsp;% filmov stavalo nepoctivosť do hrdinského svetla, prípadne ju odôvodňovalo okolnosťami, v ktorých sa hlavný hrdina nachádzal. Zistil tiež, že 38&nbsp;% filmov predstavovalo trestnú činnosť ako niečo, čo sa vypláca, alebo ako úspešnú a vzrušujúcu zábavu bez negatívnych dôsledkov. Hrdinovia v&nbsp;59&nbsp;% filmov zabili minimálne jedného človeka. 72&nbsp;% hrdiniek bolo v rôznej miere predstavovaných ako sexuálne promiskuitných, pričom iba jeden z filmov vyobrazoval normálne sexuálne vzťahy medzi manželmi. Iba v&nbsp;22&nbsp;% filmov žila niektorá z hlavných postáv v zdravom a spokojnom manželstve.<a href="#_edn44" id="_ednref44">[44]</a></p>
        <p>Častým argumentom proti kritike násilia a sexuality vo filmoch je to, že takéto veci existujú v reálnom živote a že filmy iba odrážajú skutočnosť a nie je to tak, že by mali samy osebe negatívny dopad. Avšak na základe vyššie uvedených údajov je to preukázateľne nepravdivé. Navyše početné filmy produkované hollywoodsku ľavicou prirodzene odrážajú ľavicové hodnoty a naopak zmenili hodnoty spoločnosti. Podľa filmového kritika a bývalého hollywoodskeho scenáristu Michaela Medveda formujú liberálne založení sociálni revolucionári v Hollywoode hodnoty spoločnosti tým, že napádajú legitimitu rodiny, podporujú sexuálnu zvrátenosť a&nbsp;oslavujú ohavnosť.<a href="#_edn45" id="_ednref45">[45]</a></p>
        <p>Iní argumentujú, že nadmiera morálne degenerovaného obsahu vo filmovom priemysle je len motivovaná trhovými silami. Avšak rýchlosť a sila, s akou sa filmový priemysel využíva na&nbsp;znižovanie verejnej morálky a dosahovanie diabolských cieľov, sú ohromujúce. V niektorých filmoch sa idolizujú šelmy alebo monštrá. Filmy, ktoré zobrazujú človeka premieňajúceho sa na zviera, alebo správajúceho sa beštiálne, sú uznávané a vychvaľované Hollywoodskym mainstreamom. Z duchovného hľadiska to možno chápať ako prejav toho, ako duch komunizmu dostal svet pod svoju kontrolu – ľudstvo začalo obdivovať démonov a monštrá.</p>
        <p>Tieto filmy zamerané proti tradícii na povrchu sofistikovane riešia a skúmajú rôzne sociálne otázky. Sú to však len výhovorky, aby sa vytvorilo spletité a farbisté prostredie, do ktorého sa publikum ponorí. Ohavné skutky, s ktorými konvenčná spoločnosť nesúhlasí, je možné vždy nejakým spôsobom odôvodniť, mať s nimi účasť alebo ich dokonca vykresliť ako pozitívne. Starostlivo vytvorená atmosféra divákom navodzuje pocit, že morálne štandardy sú podmienené okolnosťami. Konečným posolstvom, vštepeným do mysle divákov, je, že neexistuje jasná hranica medzi správnym a zlým alebo dobrom a zlom, že tradície sú nudné a utláčajúce a že morálka je relatívna.</p>
        <h2>6. Televízia: mravný úpadok v každej domácnosti</h2>
        <p>Televízia sa stala všadeprítomnou súčasťou každodenného života. Časté sledovanie televízie vedie ľudí k&nbsp;tomu, že zmenia svetonázor bez toho, aby si to uvedomovali. Na tento bod sa zameral výskum, ktorý vykonal tím z amerického Strediska pre výskum médií. Zistilo sa napríklad, že čím viac ľudia sledujú televíziu, tým menej sa cítia byť viazaní tradičnými cnosťami ako je úprimnosť, spoľahlivosť a spravodlivosť, a tým miernejší je ich postoj v otázkach sexuálnej morálky, ako je sex mimo manželstva, potrat a homosexualita.<a href="#_edn46" id="_ednref46">[46]</a></p>
        <p>Tento výskum porovnával dva druhy ľudí: občasných televíznych divákov, ktorí uviedli, že veria v Boha, a pravidelných televíznych divákov, ktorí tiež uviedli, že veria v Boha. Hoci podiel tých, ktorí verili v Boha, bol u oboch skupín takmer rovnaký (85 % a&nbsp;88 %), štúdia zistila, že čím viac človek sleduje televíziu, tým ťažšie pre neho je, aby si vážil náboženské &nbsp;zásady. Napríklad, keď bol respondent požiadaný, aby si z dotazníka vybral medzi tvrdeniami: „Ľudia by mali vždy žiť podľa Božieho učenia“ a „Ľudia by mali skombinovať svoj osobný súbor morálky a hodnôt s&nbsp;Božím učením“, tí, ktorí viac sledovali televíziu, si vyberali skôr druhý variant. Z týchto čísel možno všeobecne konštatovať, že sledovanie televízie spôsobuje, že ľudia sú náchylnejší k&nbsp;morálnemu relativizmu.</p>
        <p>Televízia je neoddeliteľnou súčasťou každodenného života od 50. rokov. Nielen televízne seriály a filmy dosahujú podobný účinok formovania hodnôt ľudí. Talk show, situačné komédie a dokonca aj dokumentárne filmy prostredníctvom každodenného nepretržitého opakovania svojmu publiku nepozorovane vštepujú všemožné odchýlené myšlienky.</p>
        <p>Zoberme si ako príklad talk show. Televízne štúdiá obzvlášť radi pozývajú hostí, ktorých názory alebo správania sú v rozpore s&nbsp;tradičnými hodnotami alebo ktorých život je plný konfliktov, prípadne pozývajú „odborníkov“ na diskusiu o niektorých kontroverzných otázkach morálky. Hostia sú vyzývaní, aby „odvážne“ odhaľovali „hlboké“ alebo „zložité“ problémy svojho osobného života. Moderátor, odborníci alebo publikum v rámci relácie navrhujú rôzne „možnosti“ riešenia problémov. Aby bola zaistená popularita programu, zvyčajne sa jednotlivé situácie nijako morálne nehodnotia. Týmto spôsobom sa mnoho programov stáva miestom pre zobrazovanie poklesnutých a pokrivených spôsobov správania a perspektív. Ľudia postupne došli k tomu, že hodnoty, ktoré predtým zastávali, by sa v&nbsp;určitých špeciálnych situáciách nemali uplatňovať, čo vo výsledku popiera existenciu univerzálnych zásad.</p>
        <p>Mnoho televíznych relácií je naplnených odsúdeniahodným a nechutným obsahom, ktorý je ťažké sledovať. Niektorí moderátori či moderátorky radi nadávajú a ešte sú na to hrdí. Celý rad programov v rámci zábavy indoktrinuje ľudí vulgárnym vkusom a antikultúrnym alebo antitradičným obsahom. Tým, že diváci ich sledujú v čase, keď sú uvoľnení, nechajú sa ľahšie ovplyvniť. Ako čas plynie, ľudia necítia žiadne znepokojenie a dokonca tento materiál prijímajú a oceňujú ho, čím si ničia morálne myslenie.</p>
        <p>Situačné komédie sa opakovaným vysielaním v televízii používajú k&nbsp;normalizácii odchýlených hodnôt a správania, ktoré sa v&nbsp;skutočnom živote vyskytujú len zriedka. Ben Shapiro uvádza ako príklad scénu z epizódy nazvanej „Ten, kto sa narodí“, ktorá sa objavila v&nbsp;americkom populárnym sitkome <em>Priatelia</em>. Rossovej lesbickej exmanželke Carol sa narodí Rossovo dieťa. Ross je znepokojený tým, že lesbická milenka jeho bývalej ženy bude hrať v živote dieťaťa väčšiu úlohu než on, ale Phoebe mu vraví: „Keď som vyrastala, môj otec odišiel, mama umrela a nevlastný otec išiel za mreže, takže z kúskov všetkých mojich rodičov by som poskladala sotva jedného. A tu to maličké má troch celých rodičov. Stoja o neho toľko, že sa už dnes hádajú, kto ho bude mať najradšej. A&nbsp;ešte sa ani nenarodilo. To je najšťastnejšie dieťa na svete.“<a href="#_edn47" id="_ednref47">[47]</a> Ako Shapiro píše, epizóda zobrazuje „tehotné lesby a domácnosti s&nbsp;tromi rodičmi nielen ako niečo normálne, ale dokonca ako niečo obdivuhodné“.</p>
        <p>Moderná medicína zistila, že ľudský mozog vysiela päť rôznych druhov mozgových vĺn. Dva z nich sú mozgové vlny, ktoré sa prejavujú v bdelom stave vedomia: sú to vlny alfa a beta. Keď ľudia pracujú, ich dominantné mozgové vlny sú vlny beta. Vykazujú zvýšenú schopnosť analyzovať a využívať logické myslenie. U osoby, ktorá diskutuje, by sa mali prevažne prejavovať mozgové vlny beta. Inými slovami, ľudia v stave, v ktorom sú dominantné vlny beta, sú ostražitejší a menej poddajní. Avšak keď sú ľudia uvoľnení a dominujú u nich vlny alfa, prejavujú sa ich emócie a ich analytická schopnosť sa oslabuje. Ľudia sú za takýchto okolností podprahovo utvrdzovaní témami a názormi, ktoré sú im predstavované v televíznom programe.</p>
        <p>Televízne programy začínajú ľudí znečisťovať už v ranom veku. Výskum ukázal, že takmer dve tretiny programov v médiách, vrátane detských, obsahujú násilné scény. Ďalší výskum ukazuje, že násilný obsah v médiách znecitlivuje mladých ľudí voči násiliu a zvyšuje šancu, že sa neskôr vo svojom živote dopustia násilných činov. Niektoré detské programy sú nabité skrytými témami progresivizmu a liberalizmu. Patrí medzi ne napríklad výučba homosexuality pod nálepkou „kultúrnej rozmanitosti“. Používajú frázy ako „na celom svete je len jeden človek, ako si ty“, aby podporili nezaslúžené sebavedomie a koncept prijatia všetkých ľudí bez ohľadu na ich nemorálne správanie.</p>
        <p>Televízia a filmy majú na mládež veľmi zlý vplyv. Ich sledovanie zosilňuje v mladých sklony k násiliu, podporuje ich sexuálnu aktivitu a zvyšuje pravdepodobnosť tehotenstva v&nbsp;adolescentnom veku. Mladí ľudia pritom uvádzajú médiá, hneď po sexuálnej výchove v školách, ako druhý najdôležitejší zdroj informácií o sexuálnom správaní. Dve štúdie zistili, že u&nbsp;dospievajúcich dievčat, ktoré často sledujú programy zobrazujúce sexuálne aktivity, je v porovnaní s dievčatami, ktoré tieto programy sledujú zriedka, dvojnásobne pravdepodobnejšie, že počas troch rokov otehotnejú. Vysielané programy tiež zvyšujú riziko sexuálneho napadnutia a zapojenie sa do nebezpečného správania.<a href="#_edn48" id="_ednref48">[48]</a> Ako poukázal jeden odborník: „Médiá sú natoľko podmanivé a také plné sexu, že je ťažké pre akékoľvek dieťa, a dokonca aj pre kritika, aby tomu odolali. ... Myslím, že médiá sú našimi ozajstnými sexuálnymi vychovávateľmi.“<a href="#_edn49" id="_ednref49">[49]</a> Vzhľadom k vplyvu médií sú javy ako sex mimo manželstva, cudzoložstvo a&nbsp;iné správanie teraz považované za samozrejmé a sú brané ako súčasť bežného životného štýlu – ak s tým všetky strany súhlasia, takéto správanie sa považuje za prijateľné.</p>
        <p>Shapiro v knihe <em>Propaganda v hlavnom vysielacom čase</em> zhrnul takmer sto vplyvných amerických televíznych seriálov. Zistil, že postupom času tieto programy stále viac propagovali liberalizmus a ľavicové názory vrátane ateizmu, znevažovania viery, odmietania morálky, obdivu k promiskuite, násilia, feminizmu, homosexuality a transsexuality a odmietanie tradičných vzťahov medzi manželom a manželkou alebo medzi rodičmi a deťmi. Tvorcovia stále viac prinášajú v hlavných úlohách bezohľadných antihrdinov, ktorí nevzbudzujú sympatie. Tento typ antitradičného životného štýlu mal veľký vplyv na myslenie širokej verejnosti. Zasiahnutí sú najmä mladí ľudia.<a href="#_edn50" id="_ednref50">[50]</a> Jeden program, ktorý bežal po roku 2000 na kanáli MTV päť večerov v týždni, napríklad propagoval medzi mladým publikom zvrátené sexuálne správanie a obsah podobný ľahkému pornu.<a href="#_edn51" id="_ednref51">[51]</a></p>
        <p>Od tej doby, čo bol zavedený systém kategorizácie filmov, je možné voľne predávať veľa pornografických filmov, pokiaľ sú označené kategóriou „X“ alebo „NC-17“. Ako sa vyvinuli technológie, tieto nevhodné programy prešli z ilegality do všeobecnej spotreby a možno ich ľahko získať v požičovniach filmov, prostredníctvom platených televíznych kanálov a v hoteloch. Samozrejme, len málo hollywoodskych producentov má priamo nejakú formálnu agendu vštepovať divákom pokrivené ideológie. Lenže keď samotní producenti relácií súhlasia s konceptmi progresivizmu a liberalizmu, potom tieto pokrivené ideológie nevyhnutne skončia na obrazovkách. Skutočným plánom je morálny rozvrat a producenti, ktorí sa príliš odchýlia od meradiel stanovených nebesami, sa stávajú figúrkami zla.</p>
        <h2>7. Médiá: predné línie v totálnej vojne</h2>
        <p>Vyznávači komunistickej filozofie boja sa neštítia žiadnych prostriedkov a neboja sa prekročiť žiadne morálne normy, len aby dosiahli svoje politické ciele. V prezidentskej kampani v&nbsp;USA v roku 2016 sa kandidát Donald Trump postavil proti „politickej korektnosti“ a vyhlásil, že bude presadzovať opatrenia smerujúce k presunu americkej politiky z krajnej ľavice späť k&nbsp;tradičným hodnotám a právnemu štátu, k obnove duchovnej viery národa, k daňovým škrtom pre revitalizáciu ekonomiky, k&nbsp;zabezpečeniu hraníc a k napraveniu pokriveného obchodného vzťahu s&nbsp;komunistickou Čínou. Trumpova otvorenosť privádzala liberálov do nepríčetnosti. Vyzbrojení mainstreamovými médiami vyrazili plnou parou proti nemu, pričom sa už ani nesnažia tváriť, že poskytujú vyrovnané spravodajstvo.</p>
        <p>Ľavicové médiá používali počas prezidentskej kampane rôzne metódy, ktoré Trumpa úmyselne démonizovali a očierňovali, a znevažovali jeho priaznivcov, ktorí boli označení za rasistov, sexistov, xenofóbov neznášajúcich imigrantov a nevzdelaných belochov. To znamená, že médiá sa snažili ovplyvniť výsledky volieb manipuláciou verejnosti. Takmer 95&nbsp;% médií opakovane predpovedalo, že Trump voľby v&nbsp;roku 2016 s veľkým rozdielom prehrá. Napriek tomu bol Trump napriek všetkým očakávaniam zvolený za prezidenta.</p>
        <p>Bez ohľadu na to, aká divoká môže byť predvolebná kampaň, za normálnych okolností by sa rôzne strany a skupiny mali po skončení volieb vrátiť do normálneho fungovania. Ešte dôležitejšie je, že médiá by mali dodržiavať zásadu spravodlivosti, dať na prvé miesto národné záujmy a zachovať zásadu neutrality médií. Po prezidentských voľbách v roku 2016 v Spojených štátoch sme však videli, že médiá pokračovali vo svojej šialenej kampani, a to dokonca aj za cenu riskovania straty vlastnej povesti medzi verejnosťou. Väčšina mediálnych spoločností vo svojich správach úmyselne ignoruje úspechy Trumpovej vlády, ako je napríklad rekordne nízka nezamestnanosť, rekordný rast na akciových trhoch, americké diplomatické úspechy a takmer úplné vyhladenie ISIS.</p>
        <p>Podľa štúdie Newsbusters, analytickej pobočky Centra pre výskum médií, bolo v roku 2017 pokrytie poskytnuté Trumpovi z&nbsp;90&nbsp;% negatívne. V roku 2018 dosiahlo negatívne pokrytie 91&nbsp;%. Rich Noyes, redaktor z Newsbusters, v štúdii napísal: „Žiadnemu prezidentovi sa bezpochyby nikdy nedostalo takého negatívneho pokrytia po také dlhé obdobie ako Trumpovi.“ Navyše médiá robia všetko, čo je v ich silách, aby spomalili Trumpovu administratívu tým, že vznášajú voči Trumpovi bezdôvodné obvinenia. Napríklad rozdúchali konšpiračnú teóriu o tajnej dohode medzi Trumpom a Ruskom. Dvojica veľkých novín dokonca dostala za pokrytie tejto témy Pulitzerovu cenu. Podľa danej štúdie bolo vyšetrovanie tajnej dohody s Ruskom po dva mesiace hlavnou témou večerného spravodajstva vysielaného tromi hlavnými mediálnymi skupinami v USA, pričom išlo o takmer jednu štvrtinu celkového vysielacieho času venovaného Trumpovi.<a href="#_edn52" id="_ednref52">[52]</a> Zvláštne vyšetrovanie žalobcov však napriek tomu ani po dvoch rokoch nenašlo žiadne dôkazy, ktoré by obvinenia podporili.<a href="#_edn53" id="_ednref53">[53]</a></p>
        <p>Je známe, že médiá z&nbsp;času na čas vygenerujú falošné správy. Spravodajský televízny gigant musel v decembri 2017 dočasne na štyri týždne zbaviť funkcie jedného z reportérov, pretože si vymyslel falošnú správu, že Trump nariadil generálporučíkovi Michaelovi Flynnovi, aby kontaktoval Rusko v čase Trumpovej kandidatúry na prezidenta.<a href="#_edn54" id="_ednref54">[54]</a> Daný reportér a producent, ktorý s ním pracoval, nakoniec museli televíznu stanicu opustiť. Tento konkrétny tím pritom v minulosti dosiahol skvelé úspechy, vyhral štyri ceny Peabody a sedemnásť ocenení Emmy.</p>
        <p>Keď Trump odsúdil neslávne známy gang MS-13, a&nbsp;najmä tých členov, ktorí po ilegálnom vstupe do Spojených štátov spáchali brutálne vraždy, povedal: „Nie sú to ľudia. Sú to zvieratá, a&nbsp;musíme byť veľmi, veľmi tvrdí.“ Avšak hlavné média v Amerike jeho slová okamžite vytrhli z kontextu a tvrdili, že Trump povedal, že nelegálni prisťahovalci sú zvieratá.</p>
        <p>V júni 2018 bola v médiách a na internete hojne zdieľaná fotografia plačúceho honduraského dievčatka. Táto malé dievčatko a&nbsp;jej matka boli zastavené pohraničnou hliadkou, keď sa pokúšali preniknúť do Spojených štátov. Médiá tvrdili, že dievčina bola násilne oddelená od svojej matky, a využili túto príležitosť na kritizovanie Trumpovej pohraničnej politiky a nulovej tolerancie voči nelegálnemu prisťahovalectvu. Neskôr časopis <em>Time</em> skombinoval fotografiu malého dievčatká s fotografiou Trumpa na titulnej strane a pridal titulok „Vitajte v&nbsp;Amerike“, aby Trumpa zosmiešnil. Otec dotknutého dievčaťa však neskôr médiám oznámil, že pohraniční úradníci ju od matky neoddelili a že matka vzala dcéru so sebou proti jeho vôli.<a href="#_edn55" id="_ednref55">[55]</a></p>
        <p>Americká verejnosť si je našťastie falošných správ čoraz viac vedomá. Z prieskumu, ktorý uskutočnila Monmouth University v apríli 2018, vyplynulo, že počet Američanov, ktorí si myslia, že hlavné médiá občas vysielajú falošné správy, vzrástol z predchádzajúceho roku zo 63&nbsp;% na 77&nbsp;%.<a href="#_edn56" id="_ednref56">[56]</a> V roku 2016 zo zistenia spoločnosti Gallup vyplynulo, že dôvera Američanov médiám klesla, pričom len 32&nbsp;% ľudí malo veľkú dôveru v médiá, čo je o&nbsp;8&nbsp;% menej oproti predchádzajúcemu roku.<a href="#_edn57" id="_ednref57">[57]</a> Nie je prekvapením, že istý majiteľ veľkej mediálnej spoločnosti smutne skonštatoval, že „falošné správy sú rakovinou našej doby“.<a href="#_edn58" id="_ednref58">[58]</a></p>
        <p>Súdiac podľa výsledkov volieb v Spojených štátoch podporuje Trumpa polovica Američanov, avšak postoj médií je doslova jednostranný. Za týchto abnormálnych okolností je Trump napádaný a démonizovaný, pretože zaujíma konzervatívny politický postoj a obhajuje tradičné americké hodnoty a ideály, ktoré nemôžu koexistovať s modernou ideológiou ľavice. Ak útoky médií na Trumpa budú schopné spôsobiť, že verejnosť v neho stratí dôveru, médiá dosiahnu svoj základný cieľ – zabrániť spoločnosti, aby sa vrátila k tradícii.</p>
        <p>Ešte znepokojujúcejšia je však skutočnosť, že mnoho médií sa stalo katalyzátorom pre zosilňovanie radikálnej rétoriky, vyvolávania nepriateľstva a nenávisti a polarizovanie obyvateľstva, čím sa trhliny v spoločnosti naďalej rozširujú. Základná etika bola odhodená a&nbsp;dôsledky sa ignorujú až do tej miery, že jedna strana pokojne zničí seba samú, len aby spôsobila pád oponenta. Krajina bola zatlačená do stavu extrémneho chaosu a nebezpečenstva.</p>
        <h2>8. Navrátenie charakternosti do médií</h2>
        <p>Kvôli úlohe, ktorú médiá zohrávajú vo formovaní a určovaní verejného názoru, sa často označujú ako „štvrtú moc“ štátu, po moci výkonnej, legislatívnej a&nbsp;súdnej. Pod vplyvom ducha komunizmu sú médiá efektívne využívané na ovládanie a klamanie miliárd ľudí, pričom ničia tradície a morálku obyvateľstva.</p>
        <p>V západných krajinách sa mnoho liberálnych mediálnych spoločností stalo nástrojom pre skrývanie pravdy a klamanie ľudí. Mnohé opustili základnú profesionálnu etiku, a namiesto toho sa uchýlili k najrôznejším bezcharakterným útokom, zneužívaniu a ohováraniu, bez ohľadu na to, aký dopad to bude mať na ich povesť alebo na spoločnosť.</p>
        <p>Komunizmus bol úspešný, pretože využil ľudské nedostatky, ako je usilovanie sa o slávu a zisk, nevedomosť, lenivosť, sebectvo, zneužitie solidárnosti, súťaživosť a podobne. Niektorí novinári sa povýšene búria proti tradičným hodnotám a tvária sa pritom, že spoznali skutočnú pravdu. Niektorí sa prispôsobujú morálne poklesnutému „verejnému dopytu“, len aby si získali čitateľa a divákov. Niektorí sa prispôsobujú zníženým štandardom kvôli svojej kariére. Iní zo žiarlivosti a nepriateľstva vyrábajú falošné správy. Ďalšie veria falošným správam kvôli svojej nevedomosti a lenivosti. Niektorí zneužívajú láskavosť a súcit druhých v&nbsp;presadzovaní sociálnej spravodlivosti, a tak nakláňajú celú spoločnosť doľava, pričom na dosiahnutie svojich politických a ekonomických cieľov využívajú bezohľadné taktiky.</p>
        <p>Médiá však nesú vznešené poslanie. Majú byť zázemím, kde ľudia získajú informácie o verejných udalostiach, a sú tiež dôležitou silou pre zachovanie zdravého rozvoja spoločnosti. Objektivita a nestrannosť sú základnými etickými požiadavkami médií a&nbsp;sú kľúčom k dôvere, ktorú do nich ľudia vkladajú. Avšak v&nbsp;dnešných médiách vládne chaos, čo vážne narúša dôveru, ktorú k nim ľudia majú. Obnova poslania médií a obnovenie slávy spravodajskej profesie je ušľachtilou zodpovednosťou ľudí z tejto brandže.</p>
        <p>Médiá sú prospešné, keď dokážu pomôcť spoločnosti presadzovať a udržiavať morálku, pretože v ľudskej spoločnosti sú prítomné dobro i zlo. Je zodpovednosťou médií šíriť pravdu, velebiť cnosť a odhaľovať a obmedzovať zlo.</p>
        <p>Aby sa médiá mohli navrátiť k svojmu poslaniu, musia venovať väčšiu pozornosť veľkým udalostiam, ktoré majú vplyv na budúcnosť ľudstva. Minulé storočie bolo svedkom veľkého stretu medzi slobodným svetom a komunizmom. Aj keď sa zdalo, že ide o ideologickú konfrontáciu, bol to v skutočnosti zápas na život a na smrť medzi spravodlivosťou a zlom. Je tomu tak preto, že komunizmus ničí morálku, ktorá udržuje ľudskú civilizáciu. Aj po rozpade komunistických režimov vo východnej Európe zostáva duch komunizmu neporazený a naďalej prežíva.</p>
        <p>Dnes, keď svet prechádza veľkými zmenami, sú pravda a&nbsp;tradičné hodnoty dôležitejšie než &nbsp;inokedy. Svet potrebuje médiá, ktoré rozlišujú medzi správnym a zlým, konajú dobre a udržujú verejnú morálku. Povinnosťou všetkých odborníkov v oblasti médií je prekračovať záujmy jednotlivcov, firiem a politických strán a&nbsp;prezentovať ľuďom skutočný svet.</p>
        <p>Dnes, keď čelíme morálnemu úpadku mediálnej profesie, je nevyhnutné, aby čitatelia a diváci vedome rozlišovali medzi správnym a zlým a racionálne preskúmavali informácie vytvorené médiami. Ľudia musia posudzovať veci v súlade s morálnou tradíciou, hodnotiť spoločenské javy optikou univerzálnych hodnôt, a tým tlačiť médiá k plneniu svojho historického poslania. To je tiež kľúčom k tomu, aby ľudstvo odvrátilo vplyv komunistického ducha a našlo cestu k lepšej budúcnosti.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><a href="#_ednref1" id="_edn1">[1]</a> Thomas Jefferson, <em>The Works,</em> vol. 5 (Correspondence 1786-1789), citácie podľa: Online Library of Liberty, stránka navštívená 2. októbra 2018, http: //oll.libertyfund.org/quote/302.</p>
        <p><a href="#_ednref2" id="_edn2">[2]</a> Joseph Pulitzer, citácie podľa: Michael Lewis, „J-School Confidential“, <em>The New Republic</em>, 18. apríla 1993, <a href="https://newrepublic.com/article/72485/jschool-confidential.">https://newrepublic.com/article/72485/jschool-confidential.</a></p>
        <p><a href="#_ednref3" id="_edn3">[3]</a> Karl Marx a Friedrich Engels, „Rules of the Communist League“, <em>The Communist League,</em> Marx / Engels Internet Archive, stránka navštívená 26. apríla 2020, <a href="https://www.marxists.org/archive/marx/works/1847/communist-league">https://www.marxists.org/archive/marx/works/1847/communist-league</a> / index.htm.</p>
        <p><a href="#_ednref4" id="_edn4">[4]</a> Lin Biao, „Zai Zhongyangzhengzhiju kuodahuiyi shang de Jianghua“ [„Prejav na rozšírenej schôdzi politbyra“], v: <em>Zhongguo Wenhuadageming Wenk</em> [Zbierka dokumentov z&nbsp;Čínskej kultúrnej revolúcie], 18. mája 1966. [v čínštine]</p>
        <p><a href="#_ednref5" id="_edn5">[5]</a> Hu Qiaomu, „Baozhi shi jiaokeshu“ [„Noviny sú vyučovacie knihy“], v: <em>Hu Qiaomu Wenk</em> [Zbierka dokumentov Hu Qiaomu], (Beijing: People 'Daily Publishing House, 1994), 3: 303. [V čínštine ]</p>
        <p><a href="#_ednref6" id="_edn6">[6]</a> Han Mei, „Ying Jiemi dang'an baoguang 'liu si' siwang renshu: shiti duiji zai dixiatongdao“ [ „UK sprístupňuje dokumenty a&nbsp;počty obetí Tiananmen-u; Telá sú uložené pod zemou v&nbsp;kopách“], <em>Sound of Hope</em> <em>Radio, </em>20. decembra 2017 <a href="http://www.soundofhope.org/gb/2017/12/20/n1378413.html.">http://www.soundofhope.org/gb/2017/12/20/n1378413.html.</a> [V čínštine]</p>
        <p><a href="#_ednref7" id="_edn7">[7]</a> „Self-Immolation Hoax on Tiananmen Square“, Minghui.org, http: //en.minghui.org/cc/88/.</p>
        <p><a href="#_ednref8" id="_edn8">[8]</a> „Zongshu: Chuangxinjizhi qianghua peiyang - goujian gaosuzhi xuanchuanwenhuaduiwu“ [„Celkový prehľad: Posilňovanie mechanizmu inovácie, vytvára propagandistický tím pre kultúru vysokého kalibru“], <em>Xinhua News Network</em>, 28. septembra 2011, <a href="http://www.gov.cn">http://www.gov.cn</a> / jrzg / 2011-09 / 28 / content_1958774.htm. [V čínštine]</p>
        <p><a href="#_ednref9" id="_edn9">[9]</a> Matthew Vadum, „Journalistic Treacher“, Canada Free Press, 1. júla 2015, <a href="https://canadafreepress.com/article/journalistictreachery.">https://canadafreepress.com/article/journalistictreachery.</a></p>
        <p><a href="#_ednref10" id="_edn10">[10]</a> Marco Carynnyk, „<em>The New York Times</em> and the Great Famine“, <em>Ukrainian Weekly,</em> sv. LI, č. 37, zverejnené 11. septembra 1983, stránka navštívená 26. apríla 2020, <a href="http://www.ukrweekly.com/old/archive">http://www.ukrweekly.com/old/archive</a> / 1983 / 378320.shtml.</p>
        <p><a href="#_ednref11" id="_edn11">[11]</a> Walter Durant, citácie podľa: Robert Conquest, <em>The Harvest of Sorrow: Soviet Collectivization and the Terror-famine </em>(New York: Oxford University Press, 1986), 319.</p>
        <p><a href="#_ednref12" id="_edn12">[12]</a> Robert Conquest, citácie podľa: Arnold Beichman, „Pulitzer- Winning Lies“, <em>The Weekly Standard,</em> 12. júna 2003, https: // www.weeklystandard.com/arnold-beichman/pulitzer-winning-lies.</p>
        <p><a href="#_ednref13" id="_edn13">[13]</a> Ronald Radosh a Allis Radosh, <em>Red Star Over Hollywood: The Film Colony 's Long Romance With the Left</em> (San Francisco: Encounter Books, 2005), 80.</p>
        <p><a href="#_ednref14" id="_edn14">[14]</a> Na tom istom mieste, 105.</p>
        <p><a href="#_ednref15" id="_edn15">[15]</a> Mao Zedong, citácie podľa: Edgar Snow, <em>Random Notes on Red China,</em> 1936-1945 (Montana: Literary Licensing, LLC, 2011).</p>
        <p><a href="#_ednref16" id="_edn16">[16]</a> Ruth Price, <em>The Lives of Agnes Smedley</em> (New York: Oxford University Press, 2004), 5-9.</p>
        <p><a href="#_ednref17" id="_edn17">[17]</a> Art Swift, „Six in 10 in US See Partisan Bias in News Media“ <em>Gallup</em>, 5. apríla 2017, <a href="https://news.gallup.com/poll/207794/six-partisan-biasnews-media.aspx.">https://news.gallup.com/poll/207794/six-partisan-biasnews-media.aspx.</a></p>
        <p><a href="#_ednref18" id="_edn18">[18]</a> Tim Groseclose, <em>Left Turn: How Liberal Media Bias Distorts the American Mind </em>(New York: St. Martin's Press, 2011).</p>
        <p><a href="#_ednref19" id="_edn19">[19]</a> Na tom istom mieste, 111-122.</p>
        <p><a href="#_ednref20" id="_edn20">[20]</a> Chris Cillizza, „Just 7 Percent of Journalists Are Republicans. That’s Far Fewer than Even a Decade Ago“, <em>The Washington Post</em>, 6. mája 2014, <a href="https://www.washingtonpost.com/news/the-fix/wp/2014/05/06/just-7-percent-of-journalists-are-Republicans-thats-far-less-than-evena-decade-ago">https://www.washingtonpost.com/news/the-fix/wp/2014/05/06/just-7-percent-of-journalists-are-Republicans-thats-far-less-than-evena-decade-ago</a> /? noredirect = on &amp; utm_term = .3d0109901e1e.</p>
        <p><a href="#_ednref21" id="_edn21">[21]</a> „2016 General Election Editorial Endorsements by Major Newspapers“, <em>The American Presidency Project</em>, naposledy aktualizované 8. novembra 2016, <a href="http://www.presidency.ucsb.edu/data/2016_newspaper_endorsements.php.">http://www.presidency.ucsb.edu/data/2016_newspaper_endorsements.php.</a></p>
        <p><a href="#_ednref22" id="_edn22">[22]</a> Lydia Saad, „U.S. Conservatives outnumber Liberals by Narrowing Margin“, <em>Gallup</em>, 3. januára 2017, <a href="https://news.gallup.com/poll/201152/conservative-liberal-gap-continues-narrow-tuesday.aspx.">https://news.gallup.com/poll/201152/conservative-liberal-gap-continues-narrow-tuesday.aspx.</a></p>
        <p><a href="#_ednref23" id="_edn23">[23]</a> Ben Shapiro, <em>Primetime Propaganda: The True Hollywood Story of How the Left Took Over Your TV</em> (New York: Broadside Books, 2012), 55-85.</p>
        <p><a href="#_ednref24" id="_edn24">[24]</a> Na tom istom mieste, 161-223.</p>
        <p><a href="#_ednref25" id="_edn25">[25]</a> Na tom istom mieste, 55-85.</p>
        <p><a href="#_ednref26" id="_edn26">[26]</a> Ronald Farrar, <em>A Creed for My Profession: Walter Williams, Journalist to the World </em>(Missouri: University of Missouri, 1999).</p>
        <p><a href="#_ednref27" id="_edn27">[27]</a> S. Robert Lichter et. al., <em>The Media Elite: America's New Powerbrokers </em>(Castle Rock, CO: Adler Publishing Co., 1986).</p>
        <p><a href="#_ednref28" id="_edn28">[28]</a> Jim Kuypers, <em>Partisan Journalism: A History of Media Bias in the United States </em>(Lanham, MD: Rowmn &amp; Littlefield, 2013).</p>
        <p><a href="#_ednref29" id="_edn29">[29]</a> Bernard Goldberg, „On Media Bias, Network Stars Are Rather Clueless“, <em>The Wall Street Journal</em>, 24. mája 2001, https: //www.wsj.com / articles / SB990662606943995140.</p>
        <p><a href="#_ednref30" id="_edn30">[30]</a> Tim Groseclose a Jeff Milyo, „A Measure of Media Bias“, <em>The Quarterly Journal of Economics, </em>zv. 120, č. 4 (november 2005), 1205.</p>
        <p><a href="#_ednref31" id="_edn31">[31]</a> Bernard Cohen, citácie podľa: Maxwell E. McCombs a Donald L. Shaw, „The Agenda-Setting Function of Mass Media“, <em>The Public Opinion Quarterly</em> 36, č. 2 (leto 1972), 177.</p>
        <p><a href="#_ednref32" id="_edn32">[32]</a> Newt Gingrich, „China's Embrace of marxisti Is Bad News for Its People,“ <em>Fox News</em>, 2. júna 2018, <a href="http://www.foxnews.com/opinion">http://www.foxnews.com/opinion</a> / 2018/06/02 / newt-Gingrich-Chinas-embrace-marxisti-is-badnews-for-its-people.html.</p>
        <p><a href="#_ednref33" id="_edn33">[33]</a> Patricia Cohen, „Liberal Views Dominate Footlights“, <em>The New York Times</em>, 14. októbra 2008, <a href="https://www.nytimes.com/2008/10/15/theater">https://www.nytimes.com/2008/10/15/theater</a> / 15thea.html.</p>
        <p><a href="#_ednref34" id="_edn34">[34]</a> Groseclose, <em>Left Turn,</em> ix.</p>
        <p><a href="#_ednref35" id="_edn35">[35]</a> John Belton, <em>American Cinema / American Culture,</em> 2. vydanie (New York: McGraw-Hill Publishing Company, 2004), kap. 14.</p>
        <p><a href="#_ednref36" id="_edn36">[36]</a> „Hollywood: The Shock of Freedom in Films,“ <em>Time</em>, 8. decembra 1967, <a href="http://content.time.com/time/magazine/article/0">http://content.time.com/time/magazine/article/0</a>,9171,844256,00.html.</p>
        <p><a href="#_ednref37" id="_edn37">[37]</a> Todd Gitlin, <em>The Whole World Is Watching: Mass Media in the Making and Unmaking of the New Left</em> (Berkeley: University of California Press, 2003), 199.</p>
        <p><a href="#_ednref38" id="_edn38">[38]</a> Steven J. Ross, <em>Hollywood Left and Right: How Movie Stars Shaped American Politics</em> (New York: Oxford University Press, 2011), 322.</p>
        <p><a href="#_ednref39" id="_edn39">[39]</a> Na tom istom mieste, 338.</p>
        <p><a href="#_ednref40" id="_edn40">[40]</a> Na tom istom mieste, 338-39.</p>
        <p><a href="#_ednref41" id="_edn41">[41]</a> Na tom istom mieste, 352.</p>
        <p><a href="#_ednref42" id="_edn42">[42]</a> Ashley Haygood, „<em>The Climb of Controversial Film Content</em>“ (Magisterské práce, Liberty University, máj 2007), stránka navštívená 5. októbra 2018, <a href="https://digitalcommons.liberty.edu/cgi/viewcontent.cgi?">https://digitalcommons.liberty.edu/cgi/viewcontent.cgi?</a> &amp; httpsredir = 1 &amp; article = 1007 &amp; context = masters &amp; sei-re.</p>
        <p><a href="#_ednref43" id="_edn43">[43]</a> Dennis Hopper, citácie podľa: Peter Biskind, <em>Easy Riders, Raging Bulls: How the Sex-Drugs-and-Rock 'n' Roll Generation Saved Hollywood</em> (New York: Simon and Schuster, 1999), 74.</p>
        <p><a href="#_ednref44" id="_edn44">[44]</a> Victor B. Cline, „How the Mass Media Effects Our Values and Behavior“, <em>Issues in Religion and Psychotherapy</em>, sv. 1, č. 1 (1. októbra 1975), <a href="https://scholarsarchive.byu.edu/cgi/viewcontent.cgi?">https://scholarsarchive.byu.edu/cgi/viewcontent.cgi?</a> article = 1004 &amp; context = IRP.</p>
        <p><a href="#_ednref45" id="_edn45">[45]</a> Michael Medved, <em>Hollywood vs. America</em> (New York: Harper Perennial, 1993), 4.</p>
        <p><a href="#_ednref46" id="_edn46">[46]</a> „The Media Assault on American Values“, <em>Media Research Center,</em> stránka navštívená 26. apríla 2020, <a href="https://www.mrc.org/specialreports/media-assault-american-values.">https://www.mrc.org/specialreports/media-assault-american-values.</a></p>
        <p><a href="#_ednref47" id="_edn47">[47]</a> Shapiro, <em>Primetime Propaganda.</em></p>
        <p><a href="#_ednref48" id="_edn48">[48]</a> Jane Anderson, „The Impact of Media Use and Screen Time on Children, Adolescents, and Families“, <em>American College of Pediatricians</em>, november 2016, <a href="https://www.acpeds.org/wordpress/wp-content/uploads/11.9.16-The-Impact-of-Media-Use-and-Screen-Time-on-Children-updated-with-ref-64.pdf.">https://www.acpeds.org/wordpress/wp-content/uploads/11.9.16-The-Impact-of-Media-Use-and-Screen-Time-on-Children-updated-with-ref-64.pdf.</a></p>
        <p><a href="#_ednref49" id="_edn49">[49]</a> Jane Brown, citácie podľa: Marc Silver, „Sex and Violence on TV“, <em>Congressional Record Online</em> 141, č. 146 (19. septembra 1995): S 13810-13812, <a href="https://www.gpo.gov/fdsys/pkg/CREC-1995-09-19/html/CREC-1995-09-19-pt1-PgS13810.htm.">https://www.gpo.gov/fdsys/pkg/CREC-1995-09-19/html/CREC-1995-09-19-pt1-PgS13810.htm.</a></p>
        <p><a href="#_ednref50" id="_edn50">[50]</a> Shapiro, <em>Primetime Propaganda.</em></p>
        <p><a href="#_ednref51" id="_edn51">[51]</a> Libby Copeland, „MTV’s Provocative, Undressed': Is It Rotten to the (Soft) Core?“,<em> Los</em> <em>Angeles Times,</em> 12. februára 2001, http: //articles.latimes.com / 2001 / feb / 12 / entertainment / ca-24264.</p>
        <p><a href="#_ednref52" id="_edn52">[52]</a> Rich Noyes, „TV vs. Trump in 2018: Lots of Russia, and 91% Negative Coverage (Again!)“, <em>News Busters,</em> 6. marca 2018, https: // www.newsbusters.org/blogs/nb/rich-noyes/2018/03/06/tv-vs-trump-2018- lots-russia-and-91-negative-coverage.</p>
        <p><a href="#_ednref53" id="_edn53">[53]</a> Robert Mueller III, „Report on the Investigation Into Russian Interference in the 2016 Presidential Election“, Department of Justice, marec 2019, <a href="https://www.justice.gov/storage/report.pdf.">https://www.justice.gov/storage/report.pdf.</a></p>
        <p><a href="#_ednref54" id="_edn54">[54]</a> Julia Manchester, „Trump: ABC Should Have Fired, Fraudster 'Brian Ross“, <em>The Hill,</em> 8. decembra 2017, <a href="http://thehill.com/homenews/administration">http://thehill.com/homenews/administration</a> / 364061-trump-abc-should-have-fired-fraudster-brianross.</p>
        <p><a href="#_ednref55" id="_edn55">[55]</a> Samantha Schmidt a Kristine Phillips, „The Crying Honduran Girl on the Cover of Time Was Not Separated from Her Mother“, <em>Washington Post,</em> 22. júna 2018, <a href="https://www.washingtonpost.com/news/morning-mix/wp/2018/06/22/the-crying-honduran-girl-onthe-cover-of-time-was-not-separated-from-her-mother-fathersays/?noredirect=on&amp;utm_term=.bd08dbdaf5bc">https://www.washingtonpost.com/news/morning-mix/wp/2018/06/22/the-crying-honduran-girl-onthe-cover-of-time-was-not-separated-from-her-mother-fathersays/?noredirect=on&amp;utm_term=.bd08dbdaf5bc</a></p>
        <p><a href="#_ednref56" id="_edn56">[56]</a> „, Fake News’ Threat to Media; Editorial Decisions, Outside Actors at Fault“, <em>Monmouth University Polling Institute,</em> 2. apríla 2018, https: // www.monmouth.edu/polling-institute/reports/monmouthpoll_us_040218/.</p>
        <p><a href="#_ednref57" id="_edn57">[57]</a> Art Swift, „Americans’ Trust in Mass Media Sinks to New Low, Politics“, <em>Gallup,</em> 14. septembra 2016, <a href="https://news.gallup.com/poll/195542/Americans-trust-mass-media-Sinks-new-low.aspx.">https://news.gallup.com/poll/195542/Americans-trust-mass-media-Sinks-new-low.aspx.</a></p>
        <p><a href="#_ednref58" id="_edn58">[58]</a> Polina Marinova, „New L.A. Times Owner Tells Readers: ,Fake News Is the Cancer of Our Times’“, <em>Fortune</em>, 18. júna 2018, http: //fortune / 2018/06/18 / los-angeles-times-owner /.</p>
      <Link to={`/kapitola_14`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


